import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import "../styles/pages/contact.css"
import PageTransitionContainer from "../components/PageTransitionContainer"
import DiscussForm from "../components/Home/DiscussForm"

const ContactPageTemplate = ({
  // contactInfo,
  discussProps
}) => {
  return (
    <section id="contact">
      <div className="content">
        <h1 className="page-heading">Contact</h1>
        <p>
          Do you have questions that you need answered? Would you like to apply
          for a loan today? Have an inquiry regarding your current application?
        </p>
        <p>
          Please do not hesitate to reach out! We are here for you and your
          needs, and want to make sure that all of your questions are answered
          and to ensure you have the best experience possible!
        </p>
      </div>
      <DiscussForm {...discussProps} forContactPage={true} />
    </section>
  )
}

ContactPageTemplate.propTypes = {
  contactInfo: PropTypes.shape({
    title: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  discussProps: PropTypes.shape({
    heading: PropTypes.string,
    fields: PropTypes.array
  })
}

const ContactPage = ({ data, location }) => {
  // console.log('data', data)
  let { contactInfo, index } = data
  return (
    <PageTransitionContainer>
      <Layout location={location} page="contact">
        <ContactPageTemplate
          contactInfo={
            contactInfo &&
            contactInfo.frontmatter &&
            contactInfo.frontmatter.discussForm
              ? contactInfo.frontmatter.discussForm
              : null
          }
          discussProps={
            index && index.frontmatter && index.frontmatter.discussForm
              ? index.frontmatter.discussForm
              : null
          }
        />
        <Seo title="Contact Us | RateLock" location={location} />
      </Layout>
    </PageTransitionContainer>
  )
}

export default ContactPage

export const contactPageQuery = graphql`
  query contactPageQuery {
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        discussForm {
          heading
          fields {
            type
            name
            placeholder
            options {
              label
              value
              info
            }
          }
        }
      }
    }
    contactInfo: markdownRemark(
      frontmatter: { templateKey: { eq: "contact-page" } }
    ) {
      frontmatter {
        title
        phone
        email
        addressLine1
        addressLine2
      }
    }
  }
`
